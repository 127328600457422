import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import * as styles from "./devices.module.scss";

export default () => {
  return (
    <div className={styles.container}>
      <h3>All generations of iPhones</h3>
      <h4>
        Choose from iPhone 8, iPhone X, iPhone 11, iPhone 12 in realistic or clay variations.
      </h4>
      <StaticImage
        src="../../../../assets/images/illustrations/collection-of-iphones.png"
        alt="Collection of iPhone devices: iPhone 8, iPhone X, iPhone Xs in clay, 3D rotated green iPhone 12 Pro"
        placeholder="blurred"
        layout="constrained"
        width={1200}
      />
    </div>
  );
};
