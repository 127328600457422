import React from "react";
import TextLoop from "react-text-loop";
import PhoneVideo from '../../../../assets/videos/iphone-video.mp4'
import * as styles from "./header.module.scss";

export default () => {

  function onClick(){
    document.getElementById('templates').scrollIntoView({
      behavior: 'smooth'
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <h1>
          Eye-catching iPhone shots for {" "}
          <span className={styles.textloop}>
            <TextLoop
              mask={true}
              children={["Presentations", "Blogs", "Portfolios", "Branding", "Marketing", "Pitch Decks", "Social Media"]}
            />
          </span>
        </h1>
        <h2>
          Create 3D snapshots and animations of iPhone devices. <br />
          All in your web-browser.
        </h2>
        <button onClick={onClick}>Browse Templates</button>
      </div>
      <div className={styles.right}>
        <video src={PhoneVideo} muted loop autoPlay/>
      </div>

    </div>
  );
};
