import React from "react";
import Pricing from "../components/Common/Pricing/Pricing";
import Layout from "../components/layout";
import Features from "../components/Pages/AppStoreScreenshots/Features/Features";
import Devices from "../components/Pages/iPhone-Mockups/Devices/Devices";
import Header from "../components/Pages/iPhone-Mockups/Header/Header";
import Templates from "../components/Pages/iPhone-Mockups/Templates/Templates";
import SEO from "../components/seo";

const IphoneMockupsPage = () => {
  return (
    <Layout>
      <SEO
        title={"iPhone Mockups - 3D Snapshots & Animations Generator"}
        description={
          "Create studio-quality iPhone mockups, in your web-browser. Choose from 100s of pre-made templates to generate 3D snapshots and animations featuring iPhone devices."
        }
        ogDescription={
          "Take snapshots of iPhone devices from any angle."
        }
        twitterDescription={
          "Take snapshots of iPhone devices from any angle."
        }
      />
      <Header />
      <Templates />
      <Devices />
      <Features large/>
      <Pricing />
    </Layout>
  );
};

export default IphoneMockupsPage;
