import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import ExampleTemplate from "../../../Common/ExampleTemplate/ExampleTemplate";
import TemplateVideo from '../../../../assets/videos/3d-mockup-video-example.mp4'
import * as styles from "./templates.module.scss";

const IphoneMockupsTemplates = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      template_1: file(
        relativePath: { eq: "illustrations/3d-mockup-image-example.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            placeholder: BLURRED
          )
        }
      }
    }
  `);

  return (
    <div className={styles.container} id={'templates'}>
        <h3>
            Pre-made Templates to Get Started
        </h3>
        <h4>
          Pre-made mockups are the fastest way to start designing and get inspired. <br />Pick one that suits your use-case or create your own.
        </h4>
        <ExampleTemplate image={imageQuery.template_1} index={1} id={"b0ffc637-f477-4a64-90b0-185aa32e98e9"} alt="3D Mockup with an iPhone device and text on black background"/>
        <ExampleTemplate video={TemplateVideo} index={2} id={"6631fc60-eebc-4633-a778-b987b728a9bf"} alt="3D Video Mockup with an iPhone devices flying out on pink background"/>
        <div className={styles.browse}>
          <Link to={"/mockups/apple/iphone"}>
            <button>Browse all iPhone mockups</button>
          </Link>
        </div>
    </div>
  );
};

export default IphoneMockupsTemplates;
